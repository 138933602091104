import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Reset = React.lazy(() => import('../pages/auth/ResetPassword'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const AuthenticateOAuth = React.lazy(() => import('../pages/auth/AuthenticateOAuth'));
const RouteNotFoundRedirect = React.lazy(() => import('../pages/auth/RouteNotFoundRedirect'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

/** VERIFICATION MAIL ROUTE */
const Verification = React.lazy(() => import('../pages/auth/AccountVerification'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));

// Order Maager
const OrderManager = React.lazy(() => import('../pages/settings/OrderManager'));

const ScriptManager = React.lazy(() => import('../pages/settings/Scripts'));


const integrations = React.lazy(() => import('../pages/Integration/index'));
// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();

            // check if route is restricted by role

            const isAuthorised =
                roles &&
                roles.some((role) => {
                    return loggedInUser.role.includes(role);
                });

            console.log(isAuthorised);
            if (isAuthorised) {
                const newProps = { ...props, isAuthorised: true };
                return <Component {...newProps} />;
            } else {
                const newProps = { ...props, isAuthorised: false };
                return <Component {...newProps} />;
            }

            // authorised so return component
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/manage/orders" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    component: Dashboard,
    roles: ['admin', 'QA', 'QM'],
    route: PrivateRoute,
};

// const callsListRoute = {
//     // path: '/analyse/call(/:momentId)(/:groupId)(/:callerName)',
//     path: '/analyse/call/',
//     name: 'Call Analysis',
//     // header: 'Apps',
//     icon: FeatherIcon.BarChart2,
//     component: CallDetailFilter,
//     route: PrivateRoute,
//     roles: ['admin'],
// };

const noPageFound = {
    path: '/*',
    name: '404',
    component: RouteNotFoundRedirect,
    route: Route,
};

// const calendarAppRoutes = {
//     path: '/apps/calendar',
//     name: 'Calendar',
//     header: 'Apps',
//     icon: FeatherIcon.Calendar,
//     component: CalendarApp,
//     route: PrivateRoute,
//     roles: ['admin'],
// };

// const emailAppRoutes = {
//     path: '/apps/email',
//     name: 'Email',
//     icon: FeatherIcon.Inbox,
//     children: [
//         {
//             path: '/apps/email/inbox',
//             name: 'Inbox',
//             component: EmailInbox,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/apps/email/details',
//             name: 'Details',
//             component: EmailDetail,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/apps/email/compose',
//             name: 'Compose',
//             component: EmailCompose,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//     ]
// };

// const projectAppRoutes = {
//     path: '/apps/projects',
//     name: 'Projects',
//     icon: FeatherIcon.Briefcase,
//     children: [
//         {
//             path: '/apps/projects/list',
//             name: 'List',
//             component: ProjectList,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/apps/projects/detail',
//             name: 'Detail',
//             component: ProjectDetail,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//     ]
// };

// const taskAppRoutes = {
//     path: '/apps/tasks',
//     name: 'Tasks',
//     icon: FeatherIcon.Bookmark,
//     children: [
//         {
//             path: '/apps/tasks/list',
//             name: 'List',
//             component: TaskList,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/apps/tasks/board',
//             name: 'Board',
//             component: TaskBoard,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//     ],
// };

const appRoutes = [
    // callsListRouteAll,
    // LibraryRoutes,
    // integrationRoute,
    // ReportsRoute,
    // , calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes
];

// // pages
// const pagesRoutes = {
//     path: '/pages',
//     name: 'Pages',
//     header: 'Custom',
//     icon: FeatherIcon.FileText,
//     children: [
//         {
//             path: '/pages/starter',
//             name: 'Starter',
//             component: Starter,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/pages/profile',
//             name: 'Profile',
//             component: Profile,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/pages/activity',
//             name: 'Activity',
//             component: Activity,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/pages/invoice',
//             name: 'Invoice',
//             component: Invoice,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/pages/pricing',
//             name: 'Pricing',
//             component: Pricing,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/pages/error-404',
//             name: 'Error 404',
//             component: Error404,
//             route: Route
//         },
//         {
//             path: '/pages/error-500',
//             name: 'Error 500',
//             component: Error500,
//             route: Route
//         },
//     ]
// };

// components
// const componentsRoutes = {
//     path: '/ui',
//     name: 'UI Elements',
//     header: 'Components',
//     icon: FeatherIcon.Package,
//     children: [
//         {
//             path: '/ui/bscomponents',
//             name: 'Bootstrap UI',
//            , groupId: this.props.match.params.groupId component: BSComponents,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },
//         {
//             path: '/ui/icons',
//             name: 'Icons',
//             children: [
//                 {
//                     path: '/ui/icons/feather',
//                     name: 'Feather Icons',
//                     component: FeatherIcons,
//                     route: PrivateRoute,
//                     roles: ['admin'],
//                 },
//                 {
//                     path: '/ui/icons/unicons',
//                     name: 'Unicons Icons',
//                     component: UniconsIcons,
//                     route: PrivateRoute,
//                     roles: ['admin'],
//                 },
//             ]
//         },
//         {
//             path: '/ui/widgets',
//             name: 'Widgets',
//             component: Widgets,
//             route: PrivateRoute,
//             roles: ['admin'],
//         },

//     ]
// };

// charts
// const chartRoutes = {
//     path: '/charts',
//     name: 'Charts',
//     component: Charts,
//     icon: FeatherIcon.PieChart,
//     roles: ['admin'],
//     route: PrivateRoute
// }

// forms
// const formsRoutes = {
//     path: '/forms',
//     name: 'Forms',
//     icon: FeatherIcon.FileText,
//     children: [
//         {
//             path: '/forms/basic',
//             name: 'Basic Elements',
//             component: BasicForms,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/advanced',
//             name: 'Advanced',
//             component: FormAdvanced,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/validation',
//             name: 'Validation',
//             component: FormValidation,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/wizard',
//             name: 'Wizard',
//             component: FormWizard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/editor',
//             name: 'Editor',
//             component: Editor,
//             route: PrivateRoute,
//         },
//         {
//             path: '/forms/upload',
//             name: 'File Upload',
//             component: FileUpload,
//             route: PrivateRoute,
//         }
//     ]
// };

// const tableRoutes = {
//     path: '/tables',
//     name: 'Tables',
//     icon: FeatherIcon.Grid,
//     children: [
//         {
//             path: '/tables/basic',
//             name: 'Basic',
//             component: BasicTables,
//             route: PrivateRoute,
//         },
//         {
//             path: '/tables/advanced',
//             name: 'Advanced',
//             component: AdvancedTables,
//             route: PrivateRoute,
//         }]
// };

/*** SETTING ROUTE */
const settingRoute = {
    path: '/manage/orders',
    name: 'Bestellingen',
    icon: FeatherIcon.List,
    component: OrderManager,
    roles: ['admin'],
    route: PrivateRoute,
};

/*** SETTING ROUTE */
const scriptRoute = {
    path: '/manage/scripts',
    name: 'Bon instellingen',
    icon: FeatherIcon.Settings,
    component: ScriptManager,
    roles: ['admin'],
    route: PrivateRoute,
};

// auth
const authRoutes = {
    path: '/',
    name: 'Auth',
    children: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/reset-password/:token',
            name: 'ResetPassword',
            component: Reset,
            route: Route,
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/verify/:email',
            name: 'Verification',
            component: Verification,
            route: Route,
        },
        {
            path: '/authenticate/custom',
            name: 'Authenticate OAuth',
            component: AuthenticateOAuth,
            route: Route,
        },
    ],
};

// FOR PREVIEW FEEDBACK COMMENT
// const previewFeedbackRoute = {

// };

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];
    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    // console.log(flatRoutes);
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, 
    // dashboardRoutes, 
    ...appRoutes, authRoutes, settingRoute, scriptRoute, noPageFound];

const authProtectedRoutes = [
    // dashboardRoutes,
    ...appRoutes,
    settingRoute,
    scriptRoute

    // ,
    // pagesRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    // tableRoutes,
];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
