// @flow
import { all, call, fork, takeEvery, put } from 'redux-saga/effects';

import {   
     SET_DATE_RANGE,PAST_DATE_RANGE
} from './constants';


import * as optionsConstants from '../../constants/options';

import {
    setDateRange as setDateRangeAction, setPastDateRange as setPastDateRangeAction
} from './actions';


function* changeDateRange({ payload: { from, to } }) {
    // alert("HE")
    console.log(from, to);

   yield call(setDateRangeAction, (from, to))
}

function* changePastDateRange({ payload: { from, to } }) {
    // alert("HE")
    console.log(from, to);

   yield call(setPastDateRangeAction, (from, to))
}

export function* watchChangeDateRange() {
    yield takeEvery(SET_DATE_RANGE, changeDateRange);
}

export function* watchChangePastDateRange() {
    yield takeEvery(PAST_DATE_RANGE, changePastDateRange);
}



function* optionsSaga() {
    yield all([fork(watchChangeDateRange,watchChangePastDateRange)]);
}


export default optionsSaga;