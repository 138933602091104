import {
    SET_DATE_RANGE, PAST_DATE_RANGE
} from './constants';


import * as optionsConstants from '../../constants/options';

// Initialize the local storage for date range
if(localStorage.getItem("dateRange") == localStorage.getItem("pastDateRange") && localStorage.getItem("pastDateRange")== null){
    localStorage.setItem("dateRange", JSON.stringify(optionsConstants.DATE_RANGE));
    localStorage.setItem("pastDateRange", JSON.stringify(optionsConstants.PAST_DATE_RANGE));
}

const INIT_STATE = {
    dateRange: JSON.parse(localStorage.getItem("dateRange")),
    pastDateRange: JSON.parse(localStorage.getItem("pastDateRange"))
};


const Layout = (state = INIT_STATE, action) => {
    // console.log(action.payload, action.type);
    switch (action.type) {
        case SET_DATE_RANGE:
            localStorage.setItem("dateRange", JSON.stringify(action.payload));
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    ...action.payload
                },
            };
            case PAST_DATE_RANGE:
            localStorage.setItem("pastDateRange", JSON.stringify(action.payload));
            return {
                ...state,
                pastDateRange: {
                    ...state.pastDateRange,
                    ...action.payload
                },
            };
        default:
            return state;
    }
};

export default Layout;