import moment from 'moment';

// Calculating the to & From date initially
const dateTo = moment(new Date()).startOf('day').utc().format();
const dateFrom = moment(new Date()).subtract(7, 'days').startOf('day').utc().format();

export const DATE_RANGE = {
    from: dateFrom,
    to: dateTo,
};

const pastDateTo = moment(dateFrom).subtract(1, 'days').endOf('day').utc().format();
const pastDateFrom = moment(dateFrom).subtract(8, 'days').startOf('day').utc().format();

export const PAST_DATE_RANGE = {
    from: pastDateFrom,
    to: pastDateTo,
};
