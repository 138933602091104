import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = (props) => {
    return (
        <React.Fragment>
            <ToastContainer />
        </React.Fragment>
    );
};

export default Toast;
