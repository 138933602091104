import {    SET_DATE_RANGE, PAST_DATE_RANGE } from './constants';

export const setDateRange = (from, to) => ({
    type: SET_DATE_RANGE,
    payload: {from : from, to : to},
});



export const setPastDateRange = (from, to) => ({
    type: PAST_DATE_RANGE,
    payload: {from : from, to : to},
});